import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import StoreList from "../StoreList";
import StoreMap from "../StoreMap";
import StoreInformation from "../StoreInformation";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStoreDetails } from "../../../store/actions/austinReuseDirectory.actions";

const ResultsContainer = props => {
  const { show } = props;
  const dispatch = useDispatch();
  const { selectedStoreDetails } = useSelector(state => state.arrReducer);

  useEffect(() => {
    window.addEventListener('click', focusCallback);
    return () => {
      window.removeEventListener('click', focusCallback);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(selectedStoreDetails).length && document.body.clientWidth < 768) {
      // mobile view add a class to body here
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }, [selectedStoreDetails])

  const focusCallback = (e) => {
    if (!e.target.matches(":where(.results-container) :scope")) {
      dispatch(setSelectedStoreDetails({}));
    }
  }

  return (
    <div className="results-container container d-flex">
      {show && (
        <StoreList
          onStoreDetailsClick={(data) => {
            dispatch(setSelectedStoreDetails(data));
          }}
          selectedCard={selectedStoreDetails}
        />
      )}
      <div className="ms-3 w-100 map-block">
        <StoreMap show={show} onMarkerClick={(data) => { dispatch(setSelectedStoreDetails(data)); }}/>
        {Object.keys(selectedStoreDetails).length > 0 && (
          <StoreInformation
            selectedStoreInfo={selectedStoreDetails}
            closeStoreInfo={() => {
              dispatch(setSelectedStoreDetails({}));
            }}
          />
        )}
      </div>
    </div>
  );
};

ResultsContainer.defaultProps = {
  show: false
};

ResultsContainer.propTypes = {
  show: PropTypes.bool
};

export default ResultsContainer;
