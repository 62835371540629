import Airtable from 'airtable';


// api = Api(os.environ['AIRTABLE_API_KEY'])
// const base = new Airtable({ apiKey: 'keyF2whp3NaBwLcRT' }).base('appyuFMXfkTDzBW8s');
// const base = new Airtable({ apiKey: 'keyw2AAkw5OvYxETw' }).base('appuxsIjlX2GtHEc6');
//const base = new Airtable({ apiKey: 'key4Gs69vjgbCSBKA' }).base('appuxsIjlX2GtHEc6');
const base = new Airtable({ apiKey: 'patu6E9aoQxeTI7vv.ba204a990c2b2679dd256f13e3b8a5e8edd194f7f226a28020a8a4338155622e' }).base('appuxsIjlX2GtHEc6');


//Alex's Generated Personal Access Token
//patu6E9aoQxeTI7vv.ba204a990c2b2679dd256f13e3b8a5e8edd194f7f226a28020a8a4338155622e
export default base;