import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import mapStyles from "./mapStyles";
import { useDispatch } from "react-redux";
import { setSelectedStoreDetails } from "../../../store/actions/austinReuseDirectory.actions";
import { toggleHighlightStore } from "../../../store/actions/austinReuseDirectory.actions";

const StoreMap = (props) => {
  const { show, onMarkerClick } = props;
  const center = {
    lat: 30.266666,
    lng: -97.73333,
  };
  const [markerIndex, setMarkerIndex] = useState(null);
  const { searchedStoreData } = useSelector((state) => state.arrReducer);
  const [mapCenter, setMapCenter] = useState(center);

  const libraries = ["places"];
  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    keyboardShortcuts: false,
  };

  const orangePin = require("../../../../assets/icons/map-pin-orange.svg");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-ypg_Rh3Dqq3oStewhC6xYLNq97mekm0",
    libraries,
  });

  const dispatch = useDispatch();

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div className={"map-container h-100" + (show ? "" : " d-none")}>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={mapCenter}
        options={options}
        onLoad={onMapLoad}
        onClick={() => {
          dispatch(setSelectedStoreDetails({}));
        }}
      >
        <>
          {searchedStoreData &&
            searchedStoreData.length > 0 &&
            searchedStoreData.map((marker, index) => {
              const { Latitude, Longitude, OrgName, FormattedAddress } =
                marker.fields;
              return (
                <Marker
                  key={index}
                  onClick={() => {
                    setMarkerIndex(index);
                  }}
                  position={{
                    lat: Number(Latitude),
                    lng: Number(Longitude),
                  }}
                  icon={{
                    url: orangePin.default,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                >
                  {/* window to show the marker information like tooltip */}
                  {markerIndex === index && (
                    <InfoWindow
                      onCloseClick={() => {
                        setMarkerIndex("");
                        dispatch(toggleHighlightStore(""));
                      }}
                    >
                      <div>
                        <h6>{OrgName}</h6>
                        <div>{FormattedAddress}</div>
                        <a href className="link-click" onClick={() => {
                          onMarkerClick(marker);
                          const parent = document.querySelector('.store-list-container > .list-container-wrapper > .list-container');
                          parent.scroll(0, document.getElementById(`store-${marker.id}`).offsetTop - parent.offsetTop);
                          setTimeout(function(){
                            if(document.body.clientWidth > 768) {
                              document.getElementById('modal-'+marker.id).style.top = "0";
                            }
                          }, 1);
                        }}>Click for More Info</a>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
        </>
      </GoogleMap>
    </div>
  );
};

StoreMap.defaultProps = {
  show: false,
  onMarkerClick: () => {}
};

StoreMap.propTypes = {
  show: PropTypes.bool,
  onMarkerClick: PropTypes.func
};

export default StoreMap;
