// @flow

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const CustomDropdown = (props) => {
  const {
    list,
    placeholder,
    onselect,
    defaultSelectedIndex,
    disabled,
    hasMoreOptions,
    customClass,
    showLocationMarker,
  } = props;
  const [listOpen, toggleList] = useState<Boolean>(false);
  const dropdownRef = useRef();
  const arrowRef = useRef();

  const greyPin = require("../../../../assets/icons/map-pin-grey.svg");
  const orangePin = require("../../../../assets/icons/map-pin-orange.svg");

  const onDropdownClick = () => {
    if (!disabled) {
      toggleList(!listOpen);
    }
  };

  const closeDropdown = () => {
    toggleList(false);
  };

  const updateSelectedItem = (index) => {
    closeDropdown();
    onselect(index);
  };

  const toggleCustomDropDown = (event) => {
    if (event) {
      const type = event.which ? event.which : event.keyCode;

      switch (type) {
        case 13:
          onDropdownClick();
          event.preventDefault();
          break;
        case 40:
          if (!listOpen) {
            onDropdownClick();
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className={`dropdown__wrapper ${customClass}`}
        onMouseLeave={closeDropdown}
      >
        <div
          className="dropdown__header"
          role="button"
          tabIndex="0"
          aria-expanded={listOpen}
          aria-haspopup="true"
          onClick={onDropdownClick}
          onKeyDown={(e) => toggleCustomDropDown(e)}
          aria-label={
            hasMoreOptions
              ? placeholder || list[defaultSelectedIndex].text
              : placeholder || list[defaultSelectedIndex]
          }
        >
          {showLocationMarker && (
            <img
              src={listOpen ? orangePin.default : greyPin.default}
              alt="location pin"
              className={"location-pin " + (listOpen ? "selected" : "")}
            />            
          )}
          <div className="dropdown__header__title">
            {hasMoreOptions
              ? list[defaultSelectedIndex].text
              : list[defaultSelectedIndex]}
          </div>
          {listOpen && (
            <button
              type="button"
              aria-label="Up Arrow"
              className="arrow-icon-btn"
            >
              <span ref={arrowRef} className="material-icons arrow-icon">
                keyboard_arrow_up
              </span>
            </button>
          )}
          {!listOpen && (
            <button
              type="button"
              aria-label="Down Arrow"
              className="arrow-icon-btn"
            >
              <span ref={arrowRef} className="material-icons arrow-icon">
                keyboard_arrow_down
              </span>
            </button>
          )}
        </div>
        {list && listOpen && (
          <ul
            className="dropdown__wrapper__list"
            role="listbox"
            aria-labelledby="dropdown__wrapper__list"
          >
            {list.map((option, index) => (
              <li
                key={hasMoreOptions ? option.value : option}
                className="dropdown__wrapper__list__item"
                onKeyPress={() => updateSelectedItem(index)}
                onClick={() => updateSelectedItem(index)}
                aria-selected={parseInt(defaultSelectedIndex, 10) === index}
                aria-label={
                  parseInt(defaultSelectedIndex, 10) === index
                    ? `selected${hasMoreOptions ? option.text : option}`
                    : ""
                }
                role="option"
                tabIndex={listOpen ? 0 : -1}
              >
                {hasMoreOptions ? option.text : option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

CustomDropdown.defaultProps = {
  placeholder: "SELECT",
  defaultSelectedIndex: -1,
  disabled: false,
  hasMoreOptions: false,
  customClass: "",
  showLocationMarker: false,
};

CustomDropdown.propTypes = {
  list: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onselect: PropTypes.func.isRequired,
  defaultSelectedIndex: PropTypes.number,
  disabled: PropTypes.bool,
  hasMoreOptions: PropTypes.bool,
  customClass: PropTypes.string,
  showLocationMarker: PropTypes.bool,
};

export default CustomDropdown;
