import React from 'react';

const Footer = () => {

    return (
        <>
            <div className="footer-cont d-flex justify-content-center">
                <p className="footer-cont__text">Links to web sites are for the convenience of the user. Such links and use do not constitute an express or implied endorsement or approval of any private sector web site, or of the products or services of specifically identified companies or of any of the private entities. This information does not reflect an endorsement of these entities, and therefore assumes no responsibility for the accuracy of data contained herein, or for the success or failure of any business transaction resulting from opportunities listed in this information.</p>
            </div>
           
            <footer role="contentinfo" className="bg-dark text-white container-fluid p-3 site-footer">

                <div className="row region-footer">

                    <div className="col-md-2">

                        <div className="text-center">

                            <img

                                src="https://austintexas.gov/themes/custom/coa/images/city-seal.svg"

                            />

                        </div>

                    </div>

                    <div className="col text-right">

                        <ul className="list-inline">

                            <li className="list-inline-item">

                                <a

                                    className="text-white text-decoration-none"

                                    href="//www.austintexas.gov/sitemap"

                                    target="_blank"

                                >Site Map</a

                                >

                            </li>

                            <li className="list-inline-item">

                                <a

                                    className="text-white text-decoration-none"

                                    href="//www.austintexas.gov/page/legal-notice"

                                    target="_blank"

                                >Legal Notices</a

                                >

                            </li>

                            <li className="list-inline-item">

                                <a

                                    className="text-white text-decoration-none"

                                    href="//www.austintexas.gov/page/privacy-policy"

                                    target="_blank"

                                >Privacy Policy</a

                                >

                            </li>

                        </ul>

                    </div>

                </div>

            </footer>
        </>
    );
}

export default Footer;