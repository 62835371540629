import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const StoreInformation = (props) => {
  const { closeStoreInfo, selectedStoreInfo } = props;
  const {
    Cause,
    OrgName,
    PublicPhone,
    Website,
    OrgOverview,
    BizHours,
    BizNoHours,
    FormattedAddress,
    TAGbuytype,
    TAGsubspecialty,
    TAGconvenience,
    NeedToKnow,
    Action,
    donateBuyList,
  } = selectedStoreInfo.fields || {};

  const { selectedLocation } = useSelector((state) => state.arrReducer);

  // format the data to show the speciality list of the store
  const getFormattedData = () => {
    let formattedData = [];
    if (TAGbuytype && TAGbuytype.length > 0) {
      formattedData.push(...TAGbuytype);
    }
    if (TAGsubspecialty && TAGsubspecialty.length > 0) {
      formattedData.push(...TAGsubspecialty);
    }
    if (TAGconvenience && TAGconvenience.length > 0) {
      formattedData.push(...TAGconvenience);
    }
    return formattedData.join(" . ").toUpperCase();
  };

  // format the bizhours to match the design requirement
  const getformattedBizHours = () => {
    let updatedBizHrs = [];
    const bizHrsList = BizHours.split(",\n");
    bizHrsList.forEach((element) => {
      updatedBizHrs.push({
        day: element.split("*")[0],
        timings: element.split("*")[1],
      });
    });
    return updatedBizHrs;
  };

  // get the direction url with the origina and destination loactions
  const getDirectionsUrl = (event) => {
    event.preventDefault();
    let directionUrl = `https://www.google.com/maps/dir/${selectedLocation}/${FormattedAddress}`;
    window.open(directionUrl, "_blank");
  };

  const bizHours = BizHours ? getformattedBizHours() : [];

  const isOnlineOnly = (FormattedAddress || "").toLowerCase() === "online only";

  const actions = Action.sort().join(', ');

  return (
    <div 
    id = {'modal-'+selectedStoreInfo.id}
    className="store-info-container" style={{
        top: Object.keys(selectedStoreInfo).length && (document.body.clientWidth < 768) ? `${window.pageYOffset}px` : 0
    }}>
      <div className="store-address">
        <div className="d-flex">
          {Cause && (
            <p className="store-category">{(Cause || []).join(", ")}</p>
          )}
          <button
            type="button"
            className="close-btn"
            onClick={() => closeStoreInfo()}
          >
            <span className="material-icons close">close</span>
          </button>
        </div>
        <h1 className="store-name">{OrgName}</h1>
        <p className="address">{FormattedAddress}</p>
        {!isOnlineOnly && (
          <button
            type="button"
            onClick={(e) => getDirectionsUrl(e)}
            className="directions-link"
          >
            Get directions
            <span className="material-icons launch">launch</span>
          </button>
        )}
      </div>
      <div className="left-right-30 mb-4">
        <div>
          {PublicPhone && (
            <p className="store-number">
              <span>Store</span>
              <a href={`tel:${PublicPhone}`}>{PublicPhone}</a>
            </p>
          )}
          {Website && (
            <p className="store-site">
              <span>Website</span>
              <a href={Website} target="_blank" rel="noreferrer">
                {Website}
              </a>
            </p>
          )}
        </div>
        {OrgOverview && <p className="store-info">{OrgOverview}</p>}
        <p className="other-info">{getFormattedData()}</p>
      </div>

      {NeedToKnow && (
        <>
          <div className="blue-container mt-3">
            <p className="title">Pro tips</p>
            {actions && <p className="actions"><li><span className="bold-space">At this location you can: </span><span className="no-bold" dangerouslySetInnerHTML={{ __html: actions }}></span> </li></p>}
            <p
              className="timings"
              dangerouslySetInnerHTML={{ __html: NeedToKnow }}
            ></p>
          </div>
          <div className="separator"></div>
        </>
      )}
      {donateBuyList && donateBuyList.length > 0 && (
        <>
          <div className="left-right-30">
          <p className="title">Items</p>
            <ul>
              {donateBuyList.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
          <div className="separator"></div>
        </>
      )}
      {BizHours && (
        <>
          <div className="left-right-30 mb-4">
            <p className="heading">Hours</p>
            {(bizHours || []).length > 0 &&
              bizHours.map((ele) => {
                return (
                  <p className="timings">
                    <span>{ele.day}</span>
                    <span>{ele.timings}</span>
                  </p>
                );
              })}
          </div>
        </>
      )}
      {BizNoHours && (
        <div className="left-right-30 mb-4">
          <p className="heading">Hours</p>
          <p
            className="timings"
            dangerouslySetInnerHTML={{ __html: BizNoHours }}
          ></p>
        </div>
      )}
    </div>
  );
};

StoreInformation.defaultProps = {
  selectedStoreInfo: {},
};

StoreInformation.propTypes = {
  closeStoreInfo: PropTypes.func.isRequired,
  selectedStoreInfo: PropTypes.object,
};

export default StoreInformation;
