import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { resetFiltersAndMap } from "../../../store/actions/austinReuseDirectory.actions";

const StoreList = (props) => {
  const { onStoreDetailsClick, selectedCard } = props;
  const dispatch = useDispatch();

  const { searchedStoreData } = useSelector((state) => state.arrReducer);

  const renderStoreDetails = (data, i) => {
    const {
      OrgName,
      FormattedAddress,
      Cause,
      TAGbuytype,
      TAGsubspecialty,
      TAGconvenience,
      distance,
    } = data.fields;

    // to show the formatted list of speciality list
    const getFormattedData = () => {
      let formattedData = [];
      if (TAGbuytype && TAGbuytype.length > 0) {
        formattedData.push(...TAGbuytype);
      }
      if (TAGsubspecialty && TAGsubspecialty.length > 0) {
        formattedData.push(...TAGsubspecialty);
      }
      if (TAGconvenience && TAGconvenience.length > 0) {
        formattedData.push(...TAGconvenience);
      }
      return formattedData.join(" . ").toUpperCase();
    };

    return (
      <div
        className={
          data.id === selectedCard.id
            ? "list-container__item selected"
            : "list-container__item"
        }
        key={`store-item-${i}`}
        id={`store-${data.id}`}
        role="button"
        tabIndex="0"
        onClick={() => {
          onStoreDetailsClick(data);
        }}
      >
        {Cause && (
          <p className="list-container__item-category">{Cause.join(", ")}</p>
        )}
        {OrgName && <p className="list-container__item-name">{OrgName}</p>}
        {FormattedAddress && (
          <p className="list-container__item-address">{FormattedAddress}</p>
        )}
        <p className="list-container__item-otherinfo">{getFormattedData()}</p>
        {distance && <p className="">{distance}</p>}
      </div>
    );
  };
  const hasStoreList = searchedStoreData && searchedStoreData.length > 0;

  return (
    <div className="store-list-container">
      {hasStoreList ? (
        <div className="list-container-wrapper">
          <div className="list-container">
            {(searchedStoreData || []).map((item, i) => {
              return renderStoreDetails(item, i);
            })}
          </div>
        </div>
      ) : (
        <div className="no-list-cont d-flex flex-column justify-content-center align-items-center">
          <p className="no-list-text">No Results</p>
          <button
            type="button"
            className="reset-button"
            onClick={() => {
              dispatch(resetFiltersAndMap(true));
            }}
          >
            Reset filters and map
          </button>
        </div>
      )}
    </div>
  );
};

StoreList.defaultProps = {
  selectedCard: {},
};

StoreList.propTypes = {
  onStoreDetailsClick: PropTypes.func.isRequired,
  selectedCard: PropTypes.object,
};

export default StoreList;
