import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import SearchComponet from "../SearchComponent";
import ResultsContainer from "../ResultsContainer";
import MoreInformation from "../MoreInformation";
import {
  resetFiltersAndMap,
  displayResultsAction,
} from "../../../store/actions/austinReuseDirectory.actions";

const AustinReuseDirectory = () => {
  const { resetFilter, showResults } = useSelector((state) => state.arrReducer);
  const dispatch = useDispatch();

  return (
    <div>
      <Header />
      <main>
        <header className="header-cont row">
          <h3 className="header-cont__title d-flex justify-content-center">
            Austin Reuse Directory
          </h3>
        </header>
        <div className="locator-container">
          <SearchComponet
            onSearchClick={() => {
              dispatch(displayResultsAction(true)); // dispatches action to display store list
              dispatch(resetFiltersAndMap(false)); // dispatches action to reset map and filter
            }}
          />
          <ResultsContainer show={showResults && !resetFilter} />
          <MoreInformation />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AustinReuseDirectory;
