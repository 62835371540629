import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dropdown from "../../common/Dropdown";
// import logo from "../../../../assets/icons/ReuseDirBannerAdMOATX2024.png";
import {
  fetchDropdownData,
  handleSearchAction,
  fetchItemsDropdownOptions,
  fetchLocationDropdownOptions,
  setSelectedStoreDetails,
  displayResultsAction,
  selectedLocationAction,
} from "../../../store/actions/austinReuseDirectory.actions";

const SearchComponet = (props) => {
  const { onSearchClick } = props;
  const [lookingToIndex, setLookingToIndex] = useState(0);
  const [itemsIndex, setItemsIndex] = useState(0);
  const [locationIndex, setLocationIndex] = useState(0);
  const [showErrMsg, setShowErrMsg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDropdownData());
  }, [dispatch]);

  const { dropdownData, resetFilter } = useSelector(
    (state) => state.arrReducer
  );
  const { lookingTo, typeOfItems, location } = dropdownData || {};

  useEffect(() => {
    if (resetFilter) {
      setLookingToIndex(0);
      setItemsIndex(0);
      setLocationIndex(0);
    }
  }, [resetFilter]);

  const handleSearchClick = () => {
    if (lookingTo[lookingToIndex].toLowerCase() === "select") {
      setShowErrMsg("Looking To"); // sets dropdown on the error message
      dispatch(displayResultsAction(false)); // hides store list if any
    } else if (typeOfItems[itemsIndex].toLowerCase() === "select") {
      setShowErrMsg("Type of Items");
      dispatch(displayResultsAction(false));
    } else if (location[locationIndex].toLowerCase() === "select") {
      setShowErrMsg("Location");
      dispatch(displayResultsAction(false));
    } else {
      setShowErrMsg("");
      const requestParams = {
        // requestparams holds each dropdown selected value
        lookingTo: lookingTo[lookingToIndex],
        typeOfItems: typeOfItems[itemsIndex],
        location: location[locationIndex],
      };
      dispatch(handleSearchAction(requestParams)); // dispatches action to fetch the search results
      dispatch(setSelectedStoreDetails({})); // to reset the selected store information if any
      onSearchClick();
    }
  };

  return (
    <div className="search-container container">
      <div className="search-container__title">
        <p className="search-container__title-text">
          {/* { <a href="https://moveoutatx.org">
              <img
              src={logo}
              style={{ width: "100%", height: "", objectfit: "contain" }}
              alt=""/>
              </a>
            }  */}
          Find where to donate, resell, buy responsibly, rent, and repair items,
          while supporting our local reuse economy.
        </p>
      </div>
      <div className="note text-center mb-3">
        Note: Select from left to right.
      </div>
      {Object.keys(dropdownData).length > 0 && (
        <div className="d-flex search-container__main">
          <div className="search-container__main-section">
            <p className="search-container__main-title">Looking To</p>
            {(lookingTo || []).length > 0 && (
              <Dropdown
                list={lookingTo}
                onselect={(index) => {
                  setLookingToIndex(index); // sets the selected option index
                  setShowErrMsg("");
                  setItemsIndex(0); // resets Items dropdown options
                  setLocationIndex(0); // reset Location dropdown options
                  dispatch(fetchItemsDropdownOptions(lookingTo[index])); // disaptches action to load Items dropdown options
                }}
                defaultSelectedIndex={lookingToIndex}
                customClass="look-to-dropdown"
              />
            )}
          </div>
          <div className="search-container__main-section">
            <p className="search-container__main-title">Type of Items</p>
            {(typeOfItems || []).length > 0 && (
              <Dropdown
                list={typeOfItems}
                onselect={(index) => {
                  setItemsIndex(index);
                  setLocationIndex(0);
                  setShowErrMsg("");
                  const params = {
                    lookingTo: lookingTo[lookingToIndex],
                    typeOfItems: typeOfItems[index],
                  };
                  dispatch(fetchLocationDropdownOptions(params));
                }}
                defaultSelectedIndex={itemsIndex}
                customClass="items-dropdown"
              />
            )}
          </div>
          <div className="search-container__main-section">
            <p className="search-container__main-title">Location</p>
            {(location || []).length > 0 && (
              <Dropdown
                list={location}
                onselect={(index) => {
                  setLocationIndex(index);
                  setShowErrMsg("");
                  dispatch(selectedLocationAction(location[index]));
                }}
                defaultSelectedIndex={locationIndex}
                customClass="location-dropdown"
                showLocationMarker={true}
              />
            )}
          </div>
          <button
            type="button"
            className="search-container__main-btn"
            onClick={handleSearchClick}
          >
            Search
          </button>
        </div>
      )}
      {/* shows the error msg if any dropdown option is not selected and clicked on search button */}
      {showErrMsg && (
        <div className="d-flex justify-content-center mt-4 error-msg">
          {`Please select an option from dropdown`}&nbsp;
          <b>{showErrMsg}</b>
        </div>
      )}
    </div>
  );
};

SearchComponet.defaultProps = {};

SearchComponet.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
};

export default SearchComponet;
