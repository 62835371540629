import React from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

// const googleTranslateElementInit2 = () => {
//     new window.google.translate.TranslateElement(
//         {
//             pageLanguage: 'en',
//             autoDisplay: false
//         },
//         'google_translate_element2'
//     );
// }

const GTranslateFireEvent = (a, b) => {
  try {
    if (document.createEvent) {
      var c = document.createEvent("HTMLEvents");
      c.initEvent(b, true, true);
      a.dispatchEvent(c);
    } else {
      var c = document.createEventObject();
      a.fireEvent("on" + b, c);
    }
  } catch (e) {}
};

const doGTranslate = (a) => {
  console.log(a.target.value);
  if (a.target) a = a.target.value;
  if (a == "") return;
  var b = a.split("|")[1];
  var c;
  var d = document.getElementsByTagName("select");
  for (var i = 0; i < d.length; i++)
    if (d[i].className == "goog-te-combo") c = d[i];
  if (
    document.getElementById("google_translate_element2") == null ||
    document.getElementById("google_translate_element2").innerHTML.length ==
      0 ||
    c.length == 0 ||
    c.innerHTML.length == 0
  ) {
    setTimeout(function () {
      doGTranslate(a);
    }, 500);
  } else {
    c.value = b;
    GTranslateFireEvent(c, "change");
    GTranslateFireEvent(c, "change");
  }
};

const Header = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
        <Container fluid>
          <Navbar.Brand href="//www.austintexas.gov/">
            austintexas<span>.</span>gov
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">{/* Empty div */}</Nav>
            <Nav>
              <Nav.Link target="_blank" href="//www.austintexas.gov/resident">
                Resident
              </Nav.Link>
              <Nav.Link
                target="_blank"
                href="//www.austintexas.gov/content/business"
              >
                Business
              </Nav.Link>
              <Nav.Link target="_blank" href="//www.austintexas.gov/government">
                Government
              </Nav.Link>
              <Nav.Link
                target="_blank"
                href="//www.austintexas.gov/departments"
              >
                Departments
              </Nav.Link>
              <div id="google_translate_element2">
                <select
                  onChange={doGTranslate}
                  id="gtranslate_selector"
                  className="notranslate"
                  aria-label="Select Language"
                  title="Select Language"
                >
                  <option value="">Translate</option>
                  <option value="en|en">English</option>
                  <option value="en|es">Spanish</option>
                  <option value="en|af">Afrikaans</option>
                  <option value="en|sq">Albanian</option>
                  <option value="en|ar">Arabic</option>
                  <option value="en|hy">Armenian</option>
                  <option value="en|az">Azerbaijani</option>
                  <option value="en|eu">Basque</option>
                  <option value="en|be">Belarusian</option>
                  <option value="en|bn">Bengali</option>
                  <option value="en|bs">Bosnian</option>
                  <option value="en|bg">Bulgarian</option>
                  <option value="en|ca">Catalan</option>
                  <option value="en|ceb">Cebuano</option>
                  <option value="en|zh-CN">Chinese (Simplified)</option>
                  <option value="en|zh-TW">Chinese (Traditional)</option>
                  <option value="en|hr">Croatian</option>
                  <option value="en|cs">Czech</option>
                  <option value="en|da">Danish</option>
                  <option value="en|nl">Dutch</option>
                  <option value="en|eo">Esperanto</option>
                  <option value="en|et">Estonian</option>
                  <option value="en|tl">Filipino</option>
                  <option value="en|fi">Finnish</option>
                  <option value="en|fr">French</option>
                  <option value="en|gl">Galician</option>
                  <option value="en|ka">Georgian</option>
                  <option value="en|de">German</option>
                  <option value="en|el">Greek</option>
                  <option value="en|gu">Gujarati</option>
                  <option value="en|ht">Haitian Creole</option>
                  <option value="en|ha">Hausa</option>
                  <option value="en|iw">Hebrew</option>
                  <option value="en|hi">Hindi</option>
                  <option value="en|hmn">Hmong</option>
                  <option value="en|hu">Hungarian</option>
                  <option value="en|is">Icelandic</option>
                  <option value="en|ig">Igbo</option>
                  <option value="en|id">Indonesian</option>
                  <option value="en|ga">Irish</option>
                  <option value="en|it">Italian</option>
                  <option value="en|ja">Japanese</option>
                  <option value="en|jw">Javanese</option>
                  <option value="en|kn">Kannada</option>
                  <option value="en|km">Khmer</option>
                  <option value="en|ko">Korean</option>
                  <option value="en|lo">Lao</option>
                  <option value="en|la">Latin</option>
                  <option value="en|lv">Latvian</option>
                  <option value="en|lt">Lithuanian</option>
                  <option value="en|mk">Macedonian</option>
                  <option value="en|ms">Malay</option>
                  <option value="en|mt">Maltese</option>
                  <option value="en|mi">Maori</option>
                  <option value="en|mr">Marathi</option>
                  <option value="en|mn">Mongolian</option>
                  <option value="en|ne">Nepali</option>
                  <option value="en|no">Norwegian</option>
                  <option value="en|fa">Persian</option>
                  <option value="en|pl">Polish</option>
                  <option value="en|pt">Portuguese</option>
                  <option value="en|pa">Punjabi</option>
                  <option value="en|ro">Romanian</option>
                  <option value="en|ru">Russian</option>
                  <option value="en|sr">Serbian</option>
                  <option value="en|sk">Slovak</option>
                  <option value="en|sl">Slovenian</option>
                  <option value="en|so">Somali</option>
                  <option value="en|sw">Swahili</option>
                  <option value="en|sv">Swedish</option>
                  <option value="en|ta">Tamil</option>
                  <option value="en|te">Telugu</option>
                  <option value="en|th">Thai</option>
                  <option value="en|tr">Turkish</option>
                  <option value="en|uk">Ukrainian</option>
                  <option value="en|ur">Urdu</option>
                  <option value="en|vi">Vietnamese</option>
                  <option value="en|cy">Welsh</option>
                  <option value="en|yi">Yiddish</option>
                  <option value="en|yo">Yoruba</option>
                  <option value="en|zu">Zulu</option>
                </select>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
